<template>
  <div class="error-backdrop">
    <p class="font--h4" style="color: var(--white-contrast)">
      Ooops! Something went wrong. Please try again in a few minutes
    </p>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped land="scss">
.error-backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 24px;
  align-items: center;
  justify-content: center;
  background-color: var(--white-contrast-40);
  z-index: 99999999999;
  text-align: center;
}
</style>
